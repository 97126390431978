import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

// AI Tutor Analytics Types
export type AITutorOpenedProps = {
  blockId: string;
  blockType: string;
  packId: string;
};

export type AITutorClosedProps = {
  blockId: string;
  blockType: string;
  packId: string;
  sessionDurationMs: number;
  communicationType: Nullable<'voice' | 'text'>;
};

export type AITutorToolCalledProps = {
  blockId: string;
  blockType: string;
  packId: string;
  toolName: string;
  inputParams: Record<string, unknown>;
  // Include limited response data (like status or summary)
  responseStatus: 'success' | 'error';
  // For queryKnowledgeBase, include the number of results and average score
  resultCount?: number;
  averageScore?: number;
};

export type AITutorRatingSubmittedProps = {
  packId: string;
  blockId: string;
  blockType: string;
  rating: 'thumbsUp' | 'thumbsDown';
};

export type AITutorRatingDismissedProps = {
  packId: string;
  blockId: string;
  blockType: string;
};

export class LearningAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackEditorNewSlideClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Editor New Slide Clicked',
      this.buildEventProps(props)
    );
  }

  trackEditorConfigChanged(
    blockId: string,
    key: string,
    value: unknown,
    props?: EventProperties
  ) {
    this.analytics.track(
      'Spark Editor Config Changed',
      this.buildEventProps({
        ...props,
        blockId,
        key,
        value,
      })
    );
  }

  trackEditorCoursePreviewed(props: EventProperties) {
    this.analytics.track(
      'Spark Editor Course Previewed',
      this.buildEventProps(props)
    );
  }

  trackCourseAIPrompt(props?: EventProperties) {
    this.analytics.track('Spark Course AI Prompt', props);
  }

  trackCourseDetailActionClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Course Detail Action Clicked',
      this.buildEventProps(props)
    );
  }

  trackCourseSelected(props: EventProperties) {
    this.analytics.track('Spark Course Selected', this.buildEventProps(props));
  }

  trackCourseStarted(props: EventProperties) {
    this.analytics.track('Spark Course Started', this.buildEventProps(props));
  }

  trackLandingBookMeetingClicked(props: EventProperties) {
    this.analytics.track(
      'Learning Landing Book Meeting Clicked',
      this.buildEventProps(props)
    );
  }

  trackTryForFreeClicked(props: EventProperties) {
    this.analytics.track(
      'Learning Landing Try For Free Clicked',
      this.buildEventProps(props)
    );
  }

  trackAboutUsClicked(props: EventProperties) {
    this.analytics.track(
      'Learning Landing About Us Clicked',
      this.buildEventProps(props)
    );
  }

  trackPricingClicked(props: EventProperties) {
    this.analytics.track(
      'Learning Landing Pricing Clicked',
      this.buildEventProps(props)
    );
  }

  trackLearningDemoStarted(props: EventProperties) {
    this.analytics.track(
      'Learning Landing Pricing Clicked',
      this.buildEventProps(props)
    );
  }

  trackAssistantTemplateSelected(props: EventProperties) {
    this.analytics.track(
      'Learning Assistant Template Selected',
      this.buildEventProps(props)
    );
  }

  trackVideoDemoModalTriggered(props: EventProperties) {
    this.analytics.track(
      'Spark Demo Modal Triggered',
      this.buildEventProps(props)
    );
  }

  trackVideoDemoWatched(props: EventProperties) {
    this.analytics.track(
      'Spark Demo Video Watched',
      this.buildEventProps(props)
    );
  }

  trackCreateCourseAIButtonClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Create Course AI Button Clicked',
      this.buildEventProps(props)
    );
  }

  // AI Tutor Analytics
  trackAITutorOpened(props: AITutorOpenedProps) {
    this.analytics.track('AI Tutor Opened', this.buildEventProps(props));
  }

  trackAITutorClosed(props: AITutorClosedProps) {
    this.analytics.track('AI Tutor Closed', this.buildEventProps(props));
  }

  trackAITutorToolCalled(props: AITutorToolCalledProps) {
    this.analytics.track('AI Tutor Tool Called', this.buildEventProps(props));
  }

  trackAITutorRatingSubmitted(props: AITutorRatingSubmittedProps) {
    this.analytics.track(
      'AI Tutor Rating Submitted',
      this.buildEventProps(props)
    );
  }

  trackAITutorRatingDismissed(props: AITutorRatingDismissedProps) {
    this.analytics.track(
      'AI Tutor Rating Dismissed',
      this.buildEventProps(props)
    );
  }

  trackAICourseBuilderClicked(props: EventProperties) {
    this.analytics.track(
      'Spark AI Course Builder Clicked',
      this.buildEventProps(props)
    );
  }

  trackCreateCourseTemplateButtonClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Create Course Template Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackCreateCourseEmptyButtonClicked(props: EventProperties) {
    this.analytics.track(
      'Spark Create Course Empty Button Clicked',
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useLearningAnalytics(): LearningAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new LearningAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
